import React from 'react'

import Layout from '../components/layout'

import headerBackground from '../images/header-background.png'
import mascot from '../images/nova-electronics-mascot.png'
const AboutUs = () => (
  <Layout>
    <div className="about-us">
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <div
              style={{
                backgroundImage: 'url(' + headerBackground + ')',
              }}
              className="headerBackground"
            >
              <div className="headerSection">
                <div className="headerSectionContent">
                  <div className="headerSectionContentOne">
                    <div className="six columns">
                      <br />
                      <br />
                      <br />
                      <h1> PCB Repairs</h1>
                      <hr />
                      <p>
                        Your Sheffield Experts in repairing electronic devices
                        from catering equipment to laptops
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="sectionContent">
              <div className="row">
                <div className="six columns">
                  {' '}
                  <img src={mascot} />
                </div>
                <div className="six columns">
                  <p>
                    Nova Electronics repairs Printed Circuit Boards (PCBs) –
                    including car-keys Have your electronic car keys stopped
                    working, needing a very expensive manufacturer replacement ?
                    Nova Electronics in Sheffield for PCB repairs Pop into Nova
                    Electronics, the Sheffield electronics specialist for a
                    low-cost fix instead. That’s because we repair all types of
                    Printed Circuit Board (PCB), one of which sits inside your
                    car key. So whether it’s a broken car key or another
                    electronic device, let our engineers have a look. If it’s
                    the PCB (the chances are it is), then we can fix it. And you
                    can pocket the price difference. We do PCB repairs and
                    rebuilds in all kinds of equipment – it saves money, time
                    and landfill Modern electronic devices have many PCBs and
                    they sometimes fail. The common faults are burn-outs, blown
                    tracks and failed components. But you probably won’t need
                    new ones as we at Nova Electronics can repair and rebuild
                    them. Repairing PCBs saves you money – and saves landfill.
                    Here is a sample list some of the otherwise perfect
                    equipment we have revived with a repaired PCB: Air
                    Conditioning Units Fax Machines Food Processing Machines
                    Gaming Machines Photocopiers Power Supplies Printers Traffic
                    Monitoring Equipment Vending Machines Laundry Equipment If
                    you can remove the faulty PCB and bring it in, that makes
                    repairs cheaper still. We do painstaking diagnostic checks
                    to make sure it will function when back in place. For the
                    smaller PCB repair, we will even try to repair it there and
                    then. As always with Nova Electronics, if you are not sure
                    what’s wrong, give us a call. Even better, drop in – we are
                    your local electronics specialists and we like helping
                    customers. Nova Electronics’ principles for pricing – plain
                    speaking Nova Electronics have three principles about
                    keeping your PCB repair costs low: We talk with you about
                    the problem and the likely costs. We won’t hide anything. We
                    are known for our honesty We only do what is necessary to
                    fix the problem – we don’t do “gold-plating” If something
                    else is found or is needed – we will call you first to
                    discuss it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutUs
